<template>
  <v-row>
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Email Address Confirmation</div>
        <p class="opasity--text mt-3">Please, confirm your email address {{ email }}</p>
        <v-btn elevation="0" @click="confirm" width="300" height="48" class="mx-auto" color="primary">Confirm</v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import jwt_decode from 'jwt-decode';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    email() {
      return jwt_decode(this.$route.query.t).ead;
    },
  },
  mounted() {
    if (jwt_decode(this.$route.query.t).exp < Date.now() / 1000) {
      this.$router.push('/expired');
    }
  },
  methods: {
    notifi() {
      if (this.error == 'token__invalid') {
        this.$notify({ message: 'Email confirmation token is valid', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (this.error == 'token__expired') {
        this.$notify({
          message: 'Email confirmation token has been expired',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'token__already_used') {
        this.$notify({
          message: 'Email confirmation token has been already used',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async confirm() {
      const data = {
        token: this.$route.query.t,
      };
      await this.$store
        .dispatch('confirm', data)
        .then(() => {
          sessionStorage.setItem('jwt_token', this.$route.query.t);
          this.$store
            .dispatch('refresh')
            .then(() => {
              this.$router.push('/dashboard');
            })
            .catch(() => {
              this.$router.push('/login');
            });
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
  },
};
</script>
